import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'

import { UILayout } from 'bora-material-ui'

import LoginSectionTitle from '../LoginSectionTitle'
import InputFieldWithIcon from '../Fields/InputFieldWithIcon'
import { SignInButton } from '../Buttons'
import { selectors } from '../../provider'

import messages from '../../../../consts/messages'
import { VerificationCode } from './VerificationCode'

const phoneCodeWrapperStyles = { margin: '0 10px 0 0', width: '80px' }
const phoneWrapperStyles = { margin: '0' }

class MobilIDMethod extends React.Component {
  handleAuth = (values) => {
    const { nationalId, phoneCode, phoneNumber } = values

    this.props.handleAuth({
      source: 'mobilId',
      IDCode: nationalId,
      PhoneNo: `+${phoneCode}${phoneNumber}`,
    })
  }

  render() {
    const { isLoginAvailable, handleSubmit, code, isFetching } = this.props

    return (
      <UILayout data-test="login-right-mobilid" column>
        <LoginSectionTitle title="MobileId" />

        <VerificationCode code={code} />

        <UILayout column center margin="0 0 40px 0">
          <InputFieldWithIcon data-test="mobileId-input-personal-code" required name="nationalId" disabled={code} />
          <UILayout>
            <InputFieldWithIcon
              disabled={code}
              required
              name="phoneCode"
              data-test="mobileId-input-country-code"
              additionalWrapperStyles={phoneCodeWrapperStyles}
            />
            <InputFieldWithIcon
              disabled={code}
              required
              data-test="mobileId-input-phone-number"
              name="phoneNumber"
              additionalWrapperStyles={phoneWrapperStyles}
            />
          </UILayout>
        </UILayout>

        <SignInButton
          margin="0"
          height="64px"
          size="18px"
          translate={messages.enter}
          onClick={handleSubmit(this.handleAuth)}
          disabled={isFetching || code || !isLoginAvailable}
        />
      </UILayout>
    )
  }
}

MobilIDMethod.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoginAvailable: PropTypes.bool.isRequired,
  handleAuth: PropTypes.func.isRequired,
  code: PropTypes.string,
  isFetching: PropTypes.bool,
}

MobilIDMethod.defaultProps = {
  // This is a 4 digit number which is shown
  // to the user to compare with the one sent to the smart device
  code: null,
}

const isLoginFormValid = isValid('MobilIdForm')

export default connect((state) => ({
  isLoginAvailable: isLoginFormValid(state),
  code: selectors.getChallengeID(state),
  isFetching: state.auth.isFetching,
}))(
  reduxForm({
    form: 'MobilIdForm',
    initialValues: {
      phoneCode: '372',
      phoneNumber: '',
      nationalId: '',
    },
  })(MobilIDMethod)
)
