import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UILayout } from 'bora-material-ui'
import AuthSideMenu from '../components/AuthSideMenu'
import { actions } from '../provider'
import { getParameterByName } from '../../../utils/strings'
import { translate } from '../../Common/Translator'
import messages from '../../../consts/messages'

const xsStyles = {
  column: { column: true },
}

class LoginMaritime extends React.Component {
  componentDidMount() {
    const hash = getParameterByName('hash')
    const socialToken = getParameterByName('token')
    const errorCode = getParameterByName('error')
    const errorId = getParameterByName('errorId')
    const defaultErrorMessage = errorId ? translate(messages.warningErrorCodePrefix, { id: errorId }) : ''

    if (errorCode) this.props.signinFailed({ message: defaultErrorMessage, data: { code: errorCode } })
    if (!errorCode) this.props.signinFailed({ message: defaultErrorMessage })
    if (hash) this.props.activate(hash)
    if (socialToken) this.props.authByToken(socialToken)
  }

  render() {
    const socialToken = getParameterByName('token')
    // const defaultSignInMethod = isMaritime && !getParameterByName('hash') ? UAE_PASS_TITLE : 'mail'
    const defaultSignInMethod = 'mail'
    return (
      <Fragment>
        {socialToken ? (
          <CircularProgress />
        ) : (
          <UILayout data-test="login-wrapper" smm={xsStyles.column}>
            <AuthSideMenu
              initTab={defaultSignInMethod}
              goToPage={this.props.goToPage}
              authType="signin"
              handleAuth={this.props.signin}
            />
          </UILayout>
        )}
      </Fragment>
    )
  }
}

export default connect(null, actions)(LoginMaritime)
