import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText } from 'bora-material-ui'
import { THEME } from 'bora-material-ui/themes'

import { PlusMinusToggle, TicketTypeRow } from './components'
import IncDecDropDown from './IncDecDropDown.connect'
import messages from '../../../../consts/messages'

const xsStyles = {
  root: { maxWidth: '500px' },
  serviceBlock: { row: true, margin: '10px 0', 'flex-center': true, padding: '0 0 0 10px' },
  title: { margin: '0 auto' },
}

const getColor = (color, defaultColor) => (window.brandProps.theme === THEME.LIINILAEVAD ? color : defaultColor)

const Title = ({ text }) => (
  <UILayout column width="auto">
    <UIText
      size="20px"
      color={getColor(window.brandProps.muiTheme.palette.primary4Color, '#000000')}
      align="left"
      font={window.brandProps.muiTheme.secondFontFamily}
      sml={xsStyles.title}
      translate={text}
      {...(window.brandProps.muiTheme.ids.TicketTypeRow &&
        window.brandProps.muiTheme.ids.TicketTypeRow.titles && {
          ...window.brandProps.muiTheme.ids.TicketTypeRow.titles,
        })}
    />
  </UILayout>
)
const Passenger = (props) => <IncDecDropDown icon="iconPassenger" {...props} />
const Bicycle = (props) => <IncDecDropDown icon="iconBike" {...props} />
const Vehicle = ({ title, ...toggleProps }) => (
  <UILayout
    data-test="vehicles"
    center
    height="44px"
    margin="20px 20px 0 0"
    padding="0 0 0 14px"
    sml={xsStyles.serviceBlock}
  >
    <TicketTypeRow
      title={title}
      icon={window.brandProps.muiTheme.iconVehicle ? window.brandProps.muiTheme.iconVehicle : 'iconCar'}
    />
    <PlusMinusToggle testPrefix="vehicles" {...toggleProps} />
  </UILayout>
)
const Trailer = ({ title, ...toggleProps }) => (
  <UILayout
    data-test="trailers"
    center
    height="44px"
    margin="20px 20px 0 0"
    padding="0 0 0 14px"
    sml={xsStyles.serviceBlock}
  >
    <TicketTypeRow
      title={title}
      icon={window.brandProps.muiTheme.iconTrailer ? window.brandProps.muiTheme.iconTrailer : 'trailer'}
    />
    <PlusMinusToggle {...toggleProps} />
  </UILayout>
)
const HelpText = ({ text }) => (
  <UILayout center j-flex-center margin="30px 20px 0 0" padding="0 0 0 14px">
    <UIText size="12px" color="#94a8b2" translate={text} />
  </UILayout>
)

export const createToggleHandler = (name, isActive, change) => () => {
  change('ticketsForm', `${name}IsActive`, !isActive)
  if (!isActive) {
    const fromName = `${name}sForm`
    change(fromName, 'vehicleTicket', null)
    change(fromName, 'selectedSavedVehicle', null)
    change(fromName, 'plateNumberVehicle', null)
    change(fromName, 'vehicleSimplified', null)
  }
}

const TicketsForm = (props, context) => {
  const { intl, muiTheme } = context
  const {
    changeReduxFormField,
    areVehiclesAvailable,
    areBicyclesAvailable,
    areTrailersAvailable,
    areVehiclesActive,
    areTrailersActive,
    editMode,
    alternativeResidentPriceCategory,
    handleDriverFromReserveCase,
    addonsAccumulated,
  } = props

  const passengerText = intl.formatMessage(messages.chooseTicketType)
  const bicycleText = intl.formatMessage(messages.addBicycleTicket)
  const vehicleText = intl.formatMessage(messages.addVehicle)
  const trailerText = intl.formatMessage(messages.addTrailer)

  const handleToggleVehicle = createToggleHandler('vehicle', areVehiclesActive, changeReduxFormField)
  const handleToggleTrailer = createToggleHandler('trailer', areTrailersActive, changeReduxFormField)

  const isShowHelpText = areVehiclesAvailable || areTrailersAvailable

  return (
    <UILayout column width="100%" data-test="tickets-form" sm={xsStyles.root}>
      <Title display-if={!editMode} text={messages.tickets} />
      <Passenger
        title={passengerText}
        editMode={editMode}
        alternativeResidentPriceCategory={alternativeResidentPriceCategory}
        handleDriverFromReserveCase={handleDriverFromReserveCase}
        areVehiclesAvailable={areVehiclesAvailable}
        isAddon={false}
      />
      <Bicycle display-if={areBicyclesAvailable} title={bicycleText} editMode={editMode} isAddon={false} />
      <Vehicle
        display-if={areVehiclesAvailable}
        title={vehicleText}
        active={areVehiclesActive}
        editMode={editMode}
        buttonStyle={muiTheme.ids.buttonStyles}
        onToggle={handleToggleVehicle}
      />
      <Trailer
        display-if={areTrailersAvailable}
        title={trailerText}
        editMode={editMode}
        active={areTrailersActive}
        buttonStyle={muiTheme.ids.buttonStyles}
        onToggle={handleToggleTrailer}
      />
      {addonsAccumulated &&
        addonsAccumulated.map((addon) => (
          <IncDecDropDown
            key={addon.code}
            icon={addon.subType === 'BICYCLE' ? 'iconBike' : ''}
            title={addon.titles[intl.locale]}
            editMode={editMode}
            isAddon={true}
            addonData={addon}
            {...props}
          />
        ))}
      <HelpText display-if={isShowHelpText} text={messages.addVehicleTip} />
    </UILayout>
  )
}

TicketsForm.propTypes = {
  changeReduxFormField: PropTypes.func.isRequired,
  areVehiclesAvailable: PropTypes.bool.isRequired,
  areVehiclesActive: PropTypes.bool.isRequired,
  areTrailersAvailable: PropTypes.bool.isRequired,
  areTrailersActive: PropTypes.bool.isRequired,
  areBicyclesAvailable: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
  alternativeResidentPriceCategory: PropTypes.string,
  handleDriverFromReserveCase: PropTypes.func,
}

TicketsForm.defaultProps = {
  editMode: false,
  alternativeResidentPriceCategory: '',
  handleDriverFromReserveCase: () => {},
}

TicketsForm.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

export default React.memo(TicketsForm)
