import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { UILayout } from 'bora-material-ui'

import LoginSectionTitle from '../LoginSectionTitle'
import { IDButton } from '../Buttons'
import messages from '../../../../consts/messages'

import { actions } from '../../provider'

const IDKaartMethod = (props) => {
  const handleCardAuth = () => {
    props.handleAuth({
      source: 'cardId',
    })
  }

  return (
    <UILayout data-test="login-right-idkaaart" column>
      <LoginSectionTitle title="IdCard" />
      <IDButton
        margin="0 20px 0 0"
        icon="iconSmartId"
        translate={messages.signIDKaart}
        click={handleCardAuth}
        disabled={props.isFetching}
      />
    </UILayout>
  )
}

IDKaartMethod.propTypes = {
  lang: PropTypes.string.isRequired,
  handleAuth: PropTypes.func.isRequired,
  cardIdPluginFailed: PropTypes.func.isRequired,
}

export default connect((state) => ({ lang: state.locale, isFetching: state.auth.isFetching }), actions)(IDKaartMethod)
