import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'
import { UILayout } from 'bora-material-ui'
import AuthSideMenu from '../components/AuthSideMenu'
import { actions } from '../provider'

class RegisterMaritime extends React.Component {
  render() {
    // const defaultSignInMethod = isMaritime ? UAE_PASS_TITLE : 'mail'
    const defaultSignInMethod = 'mail'
    return (
      <Fragment>
        <UILayout data-test="login-wrapper" justify="center" center smm={{ column: true }}>
          <AuthSideMenu
            initTab={defaultSignInMethod}
            authType="signup"
            handleAuth={this.props.signup}
            goToPage={this.props.goToPage}
          />
        </UILayout>
      </Fragment>
    )
  }
}

const isSignupFormValid = isValid('signupForm')

export default connect(
  (state) => ({
    isSignupAvailable: isSignupFormValid(state),
  }),
  actions
)(reduxForm({ form: 'signupForm' })(RegisterMaritime))
