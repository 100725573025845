import React from 'react'
import PropTypes from 'prop-types'
import { UILayout } from 'bora-material-ui'

import LoginSectionTitle from '../LoginSectionTitle'
import messages from '../../../../consts/messages'
import UAEPassButton from '../Buttons/UAEPassButton'

const UAEPassMethod = ({ handleAuth, locale }) => (
  <UILayout data-test="login-right-uae" column>
    <LoginSectionTitle title="UAEPass" />
    <UAEPassButton
      locale={locale}
      style={{ marginInlineEnd: '1rem' }}
      icon="iconUAEPassAuthActive"
      translate={messages.signUAEPass}
      click={() => handleAuth({ source: 'uaePass' })}
    />
  </UILayout>
)

UAEPassMethod.propTypes = {
  handleAuth: PropTypes.func.isRequired,
}

export default UAEPassMethod
