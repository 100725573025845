import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, isValid, getFormValues } from 'redux-form'
import { compose, pathOr } from 'ramda'

import { UILayout } from 'bora-material-ui'

import LoginSectionTitle from '../LoginSectionTitle'
import InputFieldWithIcon from '../Fields/InputFieldWithIcon'
import { SignInButton } from '../Buttons'
import { DropDownField } from '../../../../formComponents'
import { selectors } from '../../provider'

import messages from '../../../../consts/messages'
import { translate } from '../../../Common/Translator'
import { VerificationCode } from './VerificationCode'

const FORM = 'SmartIdForm'

const transformCountries = (country) => ({
  ...country,
  title: translate(messages[country.key]),
  value: translate(messages[country.key]),
})

const staticCountryList = [
  { key: 'countryET', code: 'EE' },
  { key: 'countryLV', code: 'LV' },
  { key: 'countryLT', code: 'LT' },
]

const countries = staticCountryList.map(transformCountries)

const dropDownStyles = {
  width: '100%',
  margin: '0',
  padding: '0',
}

const labelContainerProps = { style: { display: 'none' } }
const inputContainerProps = { padding: '0', borderRadius: '4px' }

class SmartIDMethod extends React.PureComponent {
  handleAuth = ({ nationalId, country }) => {
    const selectedCode = pathOr('ET', [country, 'code'])(staticCountryList)
    this.props.handleAuth({
      source: 'smartId',
      IDCode: nationalId,
      CountryCode: selectedCode,
    })
  }

  render() {
    const { isLoginAvailable, handleSubmit, submitting, code, isFetching } = this.props
    return (
      <UILayout data-test="login-right-smartid" column>
        <LoginSectionTitle title="SmartId" />
        <VerificationCode code={code} />
        <UILayout data-test="country-input" column center margin="0 0 40px 0">
          <DropDownField
            name="country"
            itemsArr={countries}
            styles={dropDownStyles}
            labelContainerProps={labelContainerProps}
            inputContainerProps={inputContainerProps}
            required
            hideStar
          />
          <InputFieldWithIcon required name="nationalId" />
        </UILayout>

        <SignInButton
          margin="0"
          height="64px"
          size="18px"
          translate={messages.enter}
          onClick={handleSubmit(this.handleAuth)}
          disabled={isFetching || submitting || !isLoginAvailable}
        />
      </UILayout>
    )
  }
}

SmartIDMethod.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isLoginAvailable: PropTypes.bool.isRequired,
  handleAuth: PropTypes.func.isRequired,
  code: PropTypes.string,
  isFetching: PropTypes.bool,
}

SmartIDMethod.defaultProps = {
  // This is a 4 digit number which is shown
  // to the user to compare with the one sent to the smart device
  code: null,
}

const isLoginFormValid = isValid('SmartIdForm')

const enhance = compose(
  reduxForm({
    form: FORM,
    initialValues: {
      country: 0,
    },
  }),
  connect(
    (state) => ({
      isLoginAvailable: isLoginFormValid(state),
      code: selectors.getChallengeID(state),
      formValues: getFormValues(FORM)(state),
      isFetching: state.auth.isFetching,
    }),
    null
  )
)

export default enhance(SmartIDMethod)
