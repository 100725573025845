import React from 'react'
import PropTypes from 'prop-types'
import { compose, not, isNil } from 'ramda'
import { UILayout, UIText, UIIcon, UIFormattedNumber } from 'bora-material-ui'
import { THEME } from 'bora-material-ui/themes'
import { getStyle } from '../../../../../utils/liinilaevadThemeUtils'
import moduleStyles from '../../../../Modal/Modals.module.scss'

const notNil = compose(not, isNil)

const getFont = (font, defaultFont, muiTheme) => (muiTheme.name === THEME.LIINILAEVAD ? font : defaultFont)
const getSize = (size, defaultSize, muiTheme) => (muiTheme.name === THEME.LIINILAEVAD ? size : defaultSize)

export const TicketTypeIconTitle = ({ title = '', icon, isSection }, { muiTheme }) => {
  let fontForPassenger = {
    fontFamily: getFont('SorceSansPro, Sans-Serif', 'Roboto', muiTheme),
    color: '#000000',
    size: getSize('18px', '16px', muiTheme),
  }
  if (muiTheme.name === THEME.SAAREMAA && isSection) {
    fontForPassenger = { ...fontForPassenger, fontFamily: muiTheme.fontFamily, color: muiTheme.palette.selectedItem }
  } else if (muiTheme.name === THEME.SAAREMAA && !isSection) {
    fontForPassenger.size = muiTheme.ids.TicketTypeRow ? muiTheme.ids.TicketTypeRow.size : '16px'
  }
  return (
    <UILayout
      style={getStyle(isSection && { margin: '0px auto' })}
      width="auto"
      center
      padding={isSection && muiTheme.name === THEME.SAAREMAA ? '15px 0 4px 0' : '4px 0'}
    >
      <UIIcon
        sml={0}
        type={icon}
        width="35px"
        display-if={icon}
        margin="0 15px 0 0"
        style={{
          minHeight: '15px',
          maxHeight: '20px',
        }}
        fill={getStyle(
          '#0000D5',
          muiTheme.ids.tripHeaderDoubleRow && muiTheme.ids.tripHeaderDoubleRow.separator
            ? muiTheme.ids.tripHeaderDoubleRow.separator.color
            : ''
        )}
      />
      <UIText
        font={fontForPassenger.fontFamily}
        size={fontForPassenger.size}
        color={fontForPassenger.color}
        align="left"
        maxWidth={muiTheme.name === THEME.SAAREMAA ? '533px' : '333px'}
        whiteSpace="normal"
        lineHeight={!isSection ? '1' : '32px'}
        sml={{
          size: !isSection && muiTheme.name === THEME.SAAREMAA ? '12px' : getStyle('18px', '14px'),
          lineHeight: !isSection ? '1.2' : '32px',
        }}
        {...muiTheme.ids.TicketTypeTitle}
      >
        {title}
      </UIText>
    </UILayout>
  )
}

TicketTypeIconTitle.contextTypes = {
  muiTheme: PropTypes.any,
}

const TicketTypeRow = (
  { title, icon, price, currencyCode = '', isSection, hideZeroPrice, fromDropDown },
  { muiTheme = {} }
) => {
  const priceFont = muiTheme.name === THEME.SAAREMAA && fromDropDown ? muiTheme.fontFamily : 'Roboto'
  return (
    <UILayout
      center
      justify={muiTheme.name === THEME.SAAREMAA && isSection ? 'center' : 'space-between'}
      data-test="ticket-type-row"
      {...muiTheme.ids.TicketTypeRow}
      className={moduleStyles.direction}
    >
      <TicketTypeIconTitle title={title} icon={icon} isSection={isSection} />
      <UIText
        font={getFont(muiTheme.secondFontFamily, priceFont, muiTheme)}
        size={getSize('18px', '16px', muiTheme)}
        display-if={notNil(price)}
        color="#000000"
        className={moduleStyles.text}
        {...muiTheme.ids.TicketTypeTitle}
      >
        <UIFormattedNumber
          display-if={!(hideZeroPrice && price <= 0)}
          value={price}
          style="currency"
          currency={currencyCode}
        />
      </UIText>
    </UILayout>
  )
}

TicketTypeRow.propTypes = {
  icon: PropTypes.string,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  currencyCode: PropTypes.string,
  isSection: PropTypes.bool,
  hideZeroPrice: PropTypes.bool,
  fromDropDown: PropTypes.bool,
}
TicketTypeRow.contextTypes = {
  muiTheme: PropTypes.any,
}

TicketTypeRow.defaultProps = {
  currencyCode: '',
  isSection: false,
  hideZeroPrice: false,
  fromDropDown: false,
}

export default TicketTypeRow
