import React from 'react'
import PropTypes from 'prop-types'
import { UILayout } from 'bora-material-ui'

import LoginSectionTitle from '../LoginSectionTitle'
import { GoogleButton } from '../Buttons'
import messages from '../../../../consts/messages'

const GoogleMethod = ({ handleAuth, locale }) => (
  <UILayout data-test="login-right-google" column>
    <LoginSectionTitle title="Google" />
    <GoogleButton
      margin={locale === 'ar' ? '0' : '0 20px 0 0'}
      translate={messages.signupGoogle}
      onClick={() => handleAuth({ social: 'google' })}
    />
  </UILayout>
)

GoogleMethod.propTypes = {
  handleAuth: PropTypes.func.isRequired,
}

export default GoogleMethod
